<template>
	<div style="padding-top: 10px">
		<div v-html="content" style="width: 92%;margin: 0 auto;"></div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data() {
			return {
				content: ''
			}
		},
		computed: {
			
		},
		created() {
			this.getInfo()
		},
		mounted() {
			
		},
		methods:{
			
			getInfo() {
				let t = this
				axios({
					url: 'https://yqbapi.zjgoho.com/api/wx/aboutUs',
					timeout: 5000,
					method:'post'
				}).then(res => {
					let { code, data, msg } = res.data
					if (code == 0) {
						t.content = data.content
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.$message.error('网络连接失败', 1.5)
				})
			},
		}
	}
</script>

<style lang="less">
</style>
